nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
nav>div.links>a{
    margin: 0 .4em;
    font-size: 1.2em;
    font-weight: 300;
    padding: .4em;
    border-bottom: 2px solid transparent;
}
nav>div.links>a.active{
    font-weight: normal;
    border-bottom-color: var(--Rojo);
}
.log{
    height: 4rem;
    margin: 1rem;
}