
:root{
  --Rojo: rgb(192 0 0);
}
a{
  color: inherit;
  text-decoration: none;
}
button{
  background-color: inherit;
  border: none;
  font-size: 1rem;
}