.redes{
    display: flex;
    justify-content: center;
    padding: 2em 0;
}
.redes>a{
    width: 4em;
    height: 4em;
    display: flex;
    padding: 1em;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}
.redes>a>img{
    max-width: 100%;
    max-height: 100%;
}
.footer{
    display: flex;
    background-color: whitesmoke;
    padding: 3em 0;
}
.footer>div{
    display: flex;
    flex-direction: column;
    margin: 0 1em;
}
.footer>div>div:first-child{
    font-weight: bold;
    margin-bottom: 1em;
}
.footer a{
    transition: all 300ms;
    margin-bottom: .5em;
    font-size: .8em;
}
.footer a:hover{
    opacity: .5;
}
.rights{
    background-color: whitesmoke;
    text-align: right;
    padding: 1em;
}