.section{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
}
.section::before{
    content: '';
    position: absolute;
    top: 0;
    width: 60%;
    height: 1px;
    background-color: var(--Rojo);
}
.section h1{
    font-size: 5em;
    font-weight: 200;
    color: firebrick;
}
.botones button{
    border: 1px solid var(--Rojo);
    color: #757575;
    border-radius: .36em;
    padding: .5em;
    margin: 0 .5em;
    cursor: pointer;
    transition:  background 300ms;
}
.botones button:hover,
.botones button.sel{
    background-color: var(--Rojo);
    color: white;
}
.botones{
    display: flex;
    justify-content: center;
}
.formContacto{
    display: flex;
    flex-direction: column;
    font-family: monospace;
    color: grey;
    font-weight: 300;
    font-size: 1.1em;
    width: min(40em, 100%);
}
.formContacto>div{
    display: flex;
    margin: .5em 0;
}
.formContacto label{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 .5em;
    box-sizing: border-box;
}
.formContacto i{
    margin-left: .2em;
    opacity: .7;
    font-style: normal;
}
.formContacto textarea,
.formContacto input{
    padding: .5em;
    background-color: #fafafa;
    border: 1px solid #757575;
    margin-top: .3em;
}
.formContacto textarea{
    min-height: 5em;
    resize: vertical;
}
.formContacto .boton{
    display: flex;
    justify-content: center;
}
.formContacto .btn{
    background-color: black;
    color: white;
    font-family: inherit;
    font-weight: 300;
    padding: 1em 2em;
    font-size: .9em;
}
.carousel .slide a{
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel.carousel-slider{
    max-height: 80vh;
}
.carousel .slide img{
    max-height: 100%;
    max-width: 100%;
    width: auto!important;
}
.carousel .slider-wrapper ul,
.carousel .slider-wrapper{
    max-height: 80vh;
}
.carousel .slider-wrapper{
    background-color: rgba(0,0,0,.2);
    width: 95%!important;
    border-radius: 1rem;
}
.carousel .control-arrow{
    background-color: rgba(0, 0, 0, .8)!important;
    height: 4em;
    margin: auto!important;
}

.desc{
    width: min(50em, 100%);
    text-align: center;
    font-size: 1.2em;
}
.cuadros{
    display: flex;
    margin-top: 3rem;
}
.cuadros>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding: 1em 2em;
    box-sizing: border-box;
    width: 20rem;
    margin: 0 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px lightgray;
    cursor: pointer;
}
.cuadros>div:hover{
    box-shadow: 0 0 20px lightgray;
}
h2{
    text-align: center;
}
.cuadros h3{
    align-self: flex-start;
    text-align: left;
}
.cuadros>div>div:last-child{
    opacity: .7;
}
.cuadros .divImg{
    max-width: 100%;
    max-height: 20em;
}
.cuadros .divImg img{
    max-width: 100%;
    max-height: 100%;
}
.descServicio{
    margin-top: 1em;
    display: flex;
    /* max-height: 70vh; */
}
.descServicio>div:last-child{
    font-size: 1.2em;
    box-sizing: border-box;
}
.descServicio>div{
    padding: 2em;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 50%;
}
.descServicio>div:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
}
.descServicio img{
    max-width: 100%;
    max-height: 100%;
}